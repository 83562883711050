<script>
export default {
  name: "product-show-basic-card",
  props: {
    product: {
      type: Object,
    }
  },
  data(){
    return {
      id: null,
      name: null,
      description: null,
      status: null,
      subscriptionAmount: null
    }
  },
  mounted() {
    if (this.product){
      const p = this.product
      this.id = p.id
      this.name = p.name
      this.description = p.description
      this.status = p.status
      this.subscriptionAmount = p.subscriptionAmount
    }
  },
}
</script>

<template>
  <div class="card">
    <div class="card-header">
      <h3>{{name}}</h3>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col">
          <p>{{description}}</p>
          <p>Montant Souscription: {{subscriptionAmount?.toLocaleString()}} XAF</p>
          <p>Statut: <b-badge v-if="status" variant="success">Actif</b-badge>
            <b-badge v-else variant="warning">Inactif</b-badge></p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>