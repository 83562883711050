<script>
import Layout from '@/layouts/main.vue'
import PageHeader from "@/components/page-header.vue";
import DataLoader from "@/components/data-loader.vue";
import API from "@/plugins/axios-method";
import ProductShowBasicCard from "@/components/dashboard/products/product-show-basic-card.vue";
import ProductShowSavingProgram from "@/components/dashboard/products/product-show-saving-program.vue";
import ProductShowFundingProgram
  from "@/components/dashboard/products/funding-program/product-show-funding-program.vue";
export default {
  name: "ProductDetail",
  components: {
    ProductShowFundingProgram,
    ProductShowSavingProgram,
    ProductShowBasicCard,
    DataLoader,
    PageHeader,
    Layout
  },
  data() {
    return {
      product: null,
      showDataLoader: true
    }
  },
  mounted() {
    const id = this.$route.params.id
    this.getProduct(id)
  },
  methods: {
    getProduct(id) {
      API.get('/products/' + id)
          .then(response => {
            this.product = response.data
          })
          .catch()
          .finally(() => {
            this.showDataLoader = false
          })
    }
  }
}
</script>

<template>
  <Layout>
    <PageHeader
        :title="product?.name"
        :show-search="false"
        :show-add-button="false"
    />
    <DataLoader v-if="showDataLoader"/>
    <section class="">
      <div class="col" v-if="!showDataLoader && product">
        <ProductShowBasicCard
            :product="product"
        />
        <ProductShowSavingProgram
            v-if="product.type === 'SAVING'"
            :product="product"
        />
        <ProductShowFundingProgram
            v-if="product.type === 'SAVING'"
            :product="product"
        />
      </div>

    </section>
  </Layout>
</template>

<style scoped>

</style>