<script>
import Modal from "@/components/modal.vue";
import Axios from "@/plugins/axios";
import API from "@/plugins/axios-method";
import { helpers, required} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default {
  name: "funding-program-form-modal",
  components: {Modal},
  props: {
    title: {
      type: String,
      default: "Créer Un Programme de Financement"
    },
    contributionPrograms: {
      type: Array,
      default(){
        return []
      }
    },
    value: {},
    activeFundingProgram: {
      type: Object,
      default(){
        return null
      }
    },
  },
  setup(){
    return { v$: useVuelidate() }
  },
  data(){
    return {
      visible: false,
      rate: null,
      name: null,
      duePeriod: null,
      minAmount: null,
      maxAmount: null,
      contributionProgramArr: [],
      selectedContributionProgram: null,

      id: null,
      isSubmitting: false,
      isSubmitted: false,

      url: '/funding_programs'
    }
  },
  mounted() {
    if (this.contributionPrograms.length > 0){
      this.contributionPrograms.map(c => {
        this.contributionProgramArr.push({
          value: c['@id'],
          text: c.amount + ' XAF'
        })
      })
    }
  },
  watch: {
    value(newValue) {
      this.visible = newValue
    },
    visible(newValue){
      this.visible = newValue
    },
    contributionPrograms(){

    },
    activeFundingProgram(){
      if (this.activeFundingProgram){
        this.id = this.activeFundingProgram.id
        this.name = this.activeFundingProgram.name
        this.minAmount = this.activeFundingProgram.minAmount
        this.maxAmount = this.activeFundingProgram.maxAmount
        this.rate = this.activeFundingProgram.rate
        this.duePeriod = this.activeFundingProgram.duePeriod
        this.selectedContributionProgram = this.activeFundingProgram.contributionProgram['@id']
      }
    }
  },
  methods: {
    onModalClose(){
      this.clearForm()
      this.visible = false
      this.isSubmitted = false
      this.isSubmitting = false
      this.$emit('onModalClose', 'funding-program-form')
    },
    formSubmit(){
      if (this.activeFundingProgram){
        this.updateFundingProgram()
      }else{
        this.createFundingProgram()
      }
    },
    createFundingProgram(){
      if (this.validateForm()) return
      this.isSubmitted = true

      Axios.post(this.url, {
        name: this.name,
        minAmount: parseInt(this.minAmount),
        maxAmount: parseInt(this.maxAmount),
        rate: parseInt(this.rate),
        duePeriod: parseInt(this.duePeriod),
        contributionProgram: this.selectedContributionProgram
      })
          .then(response => {
            if (response.status === 201){
              this.createSuccess(response.data)
            }
          })
          .catch(() => {
            this.isSubmitted = false
          })

    },
    updateFundingProgram(){
      if (this.validateForm()) return
      this.isSubmitted = true

      API.patch(this.url + '/' + this.id, {
        name: this.name,
        minAmount: parseInt(this.minAmount),
        maxAmount: parseInt(this.maxAmount),
        rate: parseInt(this.rate),
        duePeriod: parseInt(this.duePeriod),
        contributionProgram: this.selectedContributionProgram
      })
          .then(response => {
            if (response.status === 200){
              this.createSuccess(response.data)
            }
          })
          .catch(() => {
            this.isSubmitted = false
          })
    },
    createSuccess(p){
      this.isSubmitting = false
      this.isSubmitted = false
      this.clearForm()
      this.onModalClose()
      this.$emit('fundingProgramCreated', p)
    },
    clearForm(){
      this.v$.$reset()
      this.name = null
      this.minAmount = null
      this.maxAmount = null
      this.rate = null
      this.duePeriod = null
      this.selectedContributionProgram = null
    },
    validateForm(){
      this.v$.$validate()
      return !!this.v$.$error
    },
  },
  validations: {
    name: {
      required: helpers.withMessage("Le nom est requis", required),
    },
    minAmount: {
      required: helpers.withMessage("Le montant mininum est requis", required),
    },
    maxAmount: {
      required: helpers.withMessage("Le montant maximum est requis", required),
    },
    rate: {
      required: helpers.withMessage("Le taux d'interet est requis", required),
    },
    duePeriod: {
      required: helpers.withMessage("La période de remboursement est requise", required),
    },
    selectedContributionProgram: {
      required: helpers.withMessage("Le program de contribution est requise", required),
    }
  }
}
</script>

<template>
  <Modal :title="title" @closeModal="onModalClose" v-model="visible">
    <div class="">
      <form @submit.prevent="formSubmit">
        <div class="mb-3">
          <label for="" class="form-label">Programme de Contribution</label>
          <b-form-select :data-choice="true" :data-choices-sorting-false="true"  :class="{'is-invalid': v$.selectedContributionProgram.$errors.length }" v-model="selectedContributionProgram" :options="contributionProgramArr"/>
          <div class="invalid-feedback" v-for="error in v$.selectedContributionProgram.$errors" :key="error.$uid">
            <span>{{ error.$message }}</span>
          </div>
        </div>
        <div class="mb-3">
          <label for="" class="form-label">Nom</label>
          <b-form-input type="text" placeholder="Nom" :class="{'is-invalid': v$.name.$errors.length }" v-model.trim="name"/>
          <div class="invalid-feedback" v-for="error in v$.name.$errors" :key="error.$uid">
            <span>{{ error.$message }}</span>
          </div>
        </div>
        <div class="mb-3">
          <label for="" class="form-label">Montant Minimum</label>
          <b-form-input type="number" placeholder="Montant Minimum" :class="{'is-invalid': v$.minAmount.$errors.length }" v-model="minAmount"/>
          <div class="invalid-feedback" v-for="error in v$.minAmount.$errors" :key="error.$uid">
            <span>{{ error.$message }}</span>
          </div>
        </div>
        <div class="mb-3">
          <label for="" class="form-label">Montant Maximum</label>
          <b-form-input type="number" placeholder="Montant Maximum" :class="{'is-invalid': v$.maxAmount.$errors.length }" v-model="maxAmount"/>
          <div class="invalid-feedback" v-for="error in v$.maxAmount.$errors" :key="error.$uid">
            <span>{{ error.$message }}</span>
          </div>
        </div>
        <div class="mb-3">
          <label for="" class="form-label">Période de remboursement (Mois)</label>
          <b-form-input type="number" placeholder="Période de remboursement (Mois)" :class="{'is-invalid': v$.duePeriod.$errors.length }" v-model="duePeriod"/>
          <div class="invalid-feedback" v-for="error in v$.duePeriod.$errors" :key="error.$uid">
            <span>{{ error.$message }}</span>
          </div>
        </div>
        <div class="mb-3">
          <label for="" class="form-label">Taux d'intéret (%)</label>
          <b-form-input type="number" step="any" placeholder="Taux d'intéret (%)" :class="{'is-invalid': v$.rate.$errors.length }" v-model="rate"/>
          <div class="invalid-feedback" v-for="error in v$.rate.$errors" :key="error.$uid">
            <span>{{ error.$message }}</span>
          </div>
        </div>
        <div class="mt-4">
          <button :disabled="isSubmitted" class="btn btn-success w-100" type="submit">
            <div v-if="isSubmitted" style="width: 20px; height: 20px" class="text-white spinner-border text-primary avatar-sm" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            <span v-if="!isSubmitted">Enregistrer</span>
          </button>
        </div>
      </form>
    </div>
  </Modal>

</template>

<style>

</style>