<script>
import API from "@/plugins/axios-method";

export default {
  name: "funding-program-table",
  props: {
    fundingPrograms: {
      type: Array,
      required: true,
      default(){
        return []
      }
    }
  },
  data(){
    return {
      activeContributionProgram: null
    }
  },
  methods: {
    updateStatus(id,status){

      const confirmation = confirm("Voulez-vous vraiment changer le statut de ce financement ?")

      if (!confirmation) return window.location.reload()

      API.patch(`/funding_programs/${id}/status`, {
        status: Number(!status)
      })
          .then(() => {

          })
          .catch(error => {
            console.log(error)
          })
    },
    openUpdateFundingProgramModal(f){
      this.$emit('openUpdateFundingProgramModal', f)
    }
  }
}
</script>

<template>
  <div class="table-responsive">
    <table class="table table-hover table-striped align-middle table-nowrap mb-0">
      <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Nom</th>
        <th scope="col">Programme de contribution (XAF)</th>
        <th scope="col">Montant minimum (XAF)</th>
        <th scope="col">Montant maximum (XAF)</th>
        <th scope="col">Taux d'intéret (%)</th>
        <th scope="col">Période de remboursement (Mois)</th>
        <th scope="col">Statut</th>
        <th scope="col">Action</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(f, index) in fundingPrograms" :key="index">
        <th scope="row">{{index + 1}}</th>
        <td>{{ f.name }}</td>
        <td>{{ f.contributionProgram?.amount }}</td>
        <td>{{ f.minAmount }}</td>
        <td>{{ f.maxAmount }}</td>
        <td>{{ f.rate }}</td>
        <td>{{ f.duePeriod }}</td>
        <td>
          <div class="form-check form-switch">
            <input class="form-check-input" @change="updateStatus(f.id, f.status)" type="checkbox" role="switch" :id="'SwitchCheck'+ index" :checked="f.status">
          </div>
        </td>
        <td>
          <a href="javascript:void(0);" role="button" @click="openUpdateFundingProgramModal(f)" class="link-primary"><i class="bx bx-pencil"></i></a>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped>

</style>