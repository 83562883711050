<script>
import API from "@/plugins/axios-method";

export default {
  name: "contribution-program-table",
  props: {
    contributionPrograms: {
      type: Array,
      required: true,
      default(){
        return []
      }
    }
  },
  data(){
    return {
      activeContributionProgram: null
    }
  },
  methods: {
    updateStatus(id,status){

      const confirmation = confirm("Voulez-vous vraiment changer le statut de ce produit ?")

      if (!confirmation) return window.location.reload()

      API.patch(`/contribution_programs/${id}/status`, {
        status: Number(!status)
      })
          .then(() => {

          })
          .catch(error => {
            console.log(error)
          })
    },
    openUpdateContributionProgramModal(c){
      this.$emit('openUpdateContributionProgramModal', c)
    }
  }
}
</script>

<template>
  <div class="table-responsive">
    <table class="table table-hover table-striped align-middle table-nowrap mb-0">
      <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Nom</th>
        <th scope="col">Montant (XAF)</th>
        <th scope="col">Statut</th>
        <th scope="col">Action</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(c, index) in contributionPrograms" :key="index">
        <th scope="row">{{index + 1}}</th>
        <td>{{ c.name }}</td>
        <td>{{ c.amount }}</td>
        <td>
          <div class="form-check form-switch">
            <input class="form-check-input" @change="updateStatus(c.id, c.status)" type="checkbox" role="switch" :id="'SwitchCheck'+ index" :checked="c.status">
          </div>
        </td>
        <td>
          <a href="javascript:void(0);" role="button" @click="openUpdateContributionProgramModal(c)" class="link-primary"><i class="bx bx-pencil"></i></a>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped>

</style>