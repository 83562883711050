<script>
import Modal from "@/components/modal.vue";
import Axios from "@/plugins/axios";
import API from "@/plugins/axios-method";
import { helpers, required} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default {
  name: 'contribution-program-form-modal',
  components: {Modal},
  props: {
    title: {
      type: String,
      default: "Créer Un Programme de Contribution"
    },
    value: {},
    activeContributionProgram: {
      type: Object,
      default(){
        return null
      }
    },
  },
  setup(){
    return { v$: useVuelidate() }
  },
  data(){
    return {
      visible: false,
      name: null,
      amount: null,
      id: null,
      isSubmitting: false,
      isSubmitted: false,
    }
  },
  watch: {
    value(newValue) {
      this.visible = newValue
    },
    visible(newValue){
      this.visible = newValue
    },
    activeContributionProgram(){
      if (this.activeContributionProgram){
        this.id = this.activeContributionProgram.id
        this.name = this.activeContributionProgram.name
        this.amount = this.activeContributionProgram.amount
      }
    }
  },
  methods: {
    onModalClose(){
      this.clearForm()
      this.visible = false
      this.isSubmitted = false
      this.isSubmitting = false
      this.$emit('onModalClose', 'contribution-program-form')
    },
    formSubmit(){
      if (this.activeContributionProgram){
        this.updateContributionProgram()
      }else{
        this.createContributionProgram()
      }
    },
    createContributionProgram(){
      if (this.validateForm()) return
      this.isSubmitted = true

      Axios.post('/contribution_programs', {
        name: this.name,
        amount: parseInt(this.amount)
      })
          .then(response => {
            if (response.status === 201){
              this.createSuccess(response.data)
            }
          })
          .catch(() => {
            this.isSubmitted = false
          })

    },
    updateContributionProgram(){
      if (this.validateForm()) return
      this.isSubmitted = true

      API.patch('/contribution_programs/' + this.id, {
        name: this.name,
        amount: parseInt(this.amount),
      })
          .then(response => {
            if (response.status === 200){
              this.createSuccess(response.data)
            }
          })
          .catch(() => {
            this.isSubmitted = false
          })
    },
    createSuccess(p){
      this.isSubmitting = false
      this.isSubmitted = false
      this.clearForm()
      this.onModalClose()
      this.$emit('contributionProgramCreated', p)
    },
    clearForm(){
      this.v$.$reset()
      this.name = null
      this.amount = null
    },
    validateForm(){
      this.v$.$validate()
      return !!this.v$.$error
    },
  },
  validations: {
    name: {
      required: helpers.withMessage("Le nom est requis", required),
    },
    amount: {
      required: helpers.withMessage("Le montant est requis", required),
    },
  }
}
</script>

<template>
  <Modal :title="title" @closeModal="onModalClose" v-model="visible">
    <div class="">
      <form @submit.prevent="formSubmit">
        <div class="mb-3">
          <label for="" class="form-label">Nom du program</label>
          <b-form-input type="text" placeholder="Nom du program" :class="{'is-invalid': v$.name.$errors.length }" v-model.trim="name"/>
          <div class="invalid-feedback" v-for="error in v$.name.$errors" :key="error.$uid">
            <span>{{ error.$message }}</span>
          </div>
        </div>
        <div class="mb-3">
          <label for="" class="form-label">Montant</label>
          <b-form-input type="number" placeholder="montant" :class="{'is-invalid': v$.amount.$errors.length }" v-model="amount"/>
          <div class="invalid-feedback" v-for="error in v$.amount.$errors" :key="error.$uid">
            <span>{{ error.$message }}</span>
          </div>
        </div>
        <div class="mt-4">
          <button :disabled="isSubmitted" class="btn btn-success w-100" type="submit">
            <div v-if="isSubmitted" style="width: 20px; height: 20px" class="text-white spinner-border text-primary avatar-sm" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            <span v-if="!isSubmitted">Enregistrer</span>
          </button>
        </div>
      </form>
    </div>
  </Modal>

</template>

<style>

</style>